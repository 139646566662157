import $ from 'jquery'
import {setCookie, getCookie} from "./cookies";

const $doc = $(document)
const activeTheme = getCookie('theme')

function showWhiteTheme(event) {
  $(".nav--toggle-theme").removeClass('nav--toggle-theme--dark')
  $('.show-b').removeClass('show-b--disabled');
  $('.show-w').removeClass('show-w--enabled');
  $('body').removeClass('black-theme');
  $('.header').removeClass('header--black');
  setCookie('theme', 'white', 7);
}

function showBlackTheme(event) {
  $(".nav--toggle-theme").addClass('nav--toggle-theme--dark')
  $('.show-b').addClass('show-b--disabled');
  $('.show-w').addClass('show-w--enabled');
  $('body').addClass('black-theme');
  $('.header').addClass('header--black');
  setCookie('theme', 'black', 7);
}

export default function initThemeToggle(){
  $doc.on('click', '.show-w', showWhiteTheme )
  $doc.on('click', '.show-b', showBlackTheme )
  if (activeTheme === 'black') showBlackTheme()
  if (activeTheme === 'white') showWhiteTheme()
}
