import $ from 'jquery'

const $doc = $(document)
const dialog = $(".login__dialog");


function loginMap() {
  $(".pin-point").on('mouseenter', function () {
    // Get data-url attribute
    const url = $(this).attr('data-url');
    const title = $(this).attr('data-title');

    $(".login__dialog h3").text(title);
    $(".login__dialog .login__dialog-login").attr('href', url);

    const position = calculatePinPosition(this);

    showDialog(position)
  })
}

function calculatePinPosition(pin) {
  const offsets = $(pin).offset();
  if (!offsets) return;
  let top = offsets.top;
  let left = offsets.left;
  const dialogWidth = dialog.outerWidth();
  const dialogHeight = dialog.outerHeight();
  const pinElement = document.getElementsByClassName('pin-point')[0];
  const pinWidth = pinElement.getBoundingClientRect().width;
  top = top - dialogHeight;
  left = (left - (dialogWidth / 2)) + (pinWidth / 2);
  return [top, left];
}

function showDialog(position) {
  dialog.css({
    top: position[0],
    left: position[1]
  });
  dialog.fadeIn();
}

function closeDialog() {
  $(".login__dialog").fadeOut();
}

export default function initLoginMap() {
  $(document).ready(function () {
    loginMap();
  });
  $(window).resize(function () {
    loginMap();
  });
  $doc.on('click', '.login__dialog-close', closeDialog)
}
