import $ from 'jquery'
import {
	CountUp
} from 'countup.js';


function countUp(){
  if ($(".stats-logos__stats").length) {
    var num1 = $("#count1").attr('num-val'),
        num2 = $("#count2").attr('num-val'),
        num3 = $("#count3").attr('num-val'),
        num4 = $("#count4").attr('num-val');

    var animated = false;
    const easingFn = function(t, b, c, d) {
      var ts = (t /= d) * t;
      var tc = ts * t;
      return b + c * (tc * ts + -5 * ts * ts + 10 * tc + -10 * ts + 5 * t);
    }
    const runAnimations = () => {
      const options1 = {
        duration: 5,
        easingFn,
      };
      const options2 = {
        duration: 7,
        easingFn,
      };
      const options3 = {
        duration: 9,
        easingFn,
      };
      const options4 = {
        duration: 7,
        easingFn,
      };

      var countUp1 = new CountUp('count1', num1, options1);
      var countUp2 = new CountUp('count2', num2, options2);
      var countUp3 = new CountUp('count3', num3, options3);
      var countUp4 = new CountUp('count4', num4, options4);
      countUp1.start();
      countUp2.start();
      countUp3.start();
      countUp4.start();
    };
    $(window).on('scroll', function() {
      var scrollTop = $(this).scrollTop();
      var topDistance = $(".stats-logos__stats").offset().top;
      if ((topDistance - 500) < scrollTop) {
        if (!animated) runAnimations();
        animated = true;
      }
    });
  }
}

export default function initCountUp(){
  $(document).ready( countUp );
}
