import $ from 'jquery'
import imagesLoaded from "imagesloaded";

const $doc = $(document)
const searchInput = $('.search-form__input')
const searchBarInput = $('.js-search-bar-input')
const searchBarResults = $('.js-search-bar-results')

function handleSearch() {
  const searchValue = searchInput.val()

  if (searchValue.length > 1) {
    const event = new CustomEvent('beforeNewQuery', {
      detail: { searchValue }
    })
    document.dispatchEvent(event)
    // Unselect selected filter category
    $('.shuffle-filter__item.selected').removeClass('selected');
    window.history.replaceState({}, document.title, ".");
  }
}

function handleEnter(e) {
  if (e.key === 'Enter') {
    handleSearch()
  }
}

function debounce(cb, delay = 50) {
  let timeout
  return (...args) => {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      cb(...args)
    }, delay)
  }
}

function handleSearchBar(e) {
  const searchValue = e.target.value
  searchBarResults.show()
  searchBarResults.text('Loading..');
  jQuery.ajax({
    url: SITE.AJAX_URL,
    type: 'POST',
    data: {
      action: 'search',
      query: searchValue
    },
    success: function (data) {
      searchBarResults.html(data)
    },
    error: function (request, status, error) {
      searchBarResults.hide()
    }
  })
}

function showSearchbar() {
  $(".header__search-bar").show()
  var searchValue = $('.search-bar__input').eq(0).val();
  if (searchValue.length > 1) {
    $(".search-bar__results").show();
  }
}

function hideSearchBar() {
  $(".header__search-bar").hide();
}

export default function initSearch() {
  $doc.on('click', '.js-search', handleSearch)
  $doc.on('click', '.js-header-search', showSearchbar)
  $doc.on('scroll', hideSearchBar)
  $doc.on('mouseenter', '.nav__list.nav__list--left li', hideSearchBar)
  searchInput.on('keyup', handleEnter)
  searchBarInput.on('input', debounce(handleSearchBar, 1000))

  $(document).on('click', function (event) {
    if (!$(event.target).closest('.search-bar__results').length && !$(event.target).closest('.header__search-bar').length && !$(event.target).closest('.search-search-bar__input').length && !$(event.target).closest('.js-header-search').length) {
      // User clicked outside the .senka div
      // Add your code here to handle the event
      $(".search-bar__results").hide();
      $(".header__search-bar--desktop").hide();
    }
  });
}
