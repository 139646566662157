import $ from 'jquery'

function initMainSlider(){
  if ($('.js-slider').length) {
    $('.js-slider').slick({
      arrows: true,
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });
  }
}

function initLogosSLider(){
  if ($('.js-logos-slider').length) {
    $('.js-logos-slider').slick({
      speed: 10000,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      slidesToShow: 4,
      slidesToScroll: 1,
      infinite: true,
      initialSlide: 1,
      arrows: false,
      buttons: false,
      responsive: [
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });
  }
}

function initShuffleFilterSlider(){
  if ($('.js-shuffle-filter').length) {
    $('.js-shuffle-filter').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      variableWidth: true,
      mobileFirst: true,
      prevArrow: '<button type="button" class="slick-prev"><span class="slick-arrow__bg"></span><span class="slick-arrow__inner"></span></button>',
      nextArrow: '<button type="button" class="slick-next"><span class="slick-arrow__bg"></span><span class="slick-arrow__inner"></span></button>',
    });
  }
}

export default function initSlider(){
  $(document).ready( function(){
    initMainSlider()
    initLogosSLider()
    initShuffleFilterSlider()
  })
}
