import $ from 'jquery'
const customNav = $('.js-custom-nav')
let el = document.getElementsByClassName('js-custom-nav')[0],
  distanceFromTop,
  customNavTriggerPoint

function fixedCustomNav() {
  $(customNav).addClass('custom-navbar--fixed')
}

function unfixCustomNav() {
  $(customNav).removeClass('custom-navbar--fixed')
}

function updateParent() {
  var elHeight = $('.js-custom-nav').outerHeight()
  $('.custom-navbar-outer').css('height', elHeight)
}

function elementTopDistance() {
  distanceFromTop = window.pageYOffset + el.getBoundingClientRect().top
  customNavTriggerPoint = distanceFromTop - 12
}

function customNavScrollEvents() {
  $(window).resize(function () {
    elementTopDistance()
    updateParent()
  })

  var lastScrollTop = 0
  $(window).scroll(function (event) {
    var st = $(this).scrollTop()
    if (st > lastScrollTop) {
      // downscroll code
      if (
        $(document).scrollTop() >= customNavTriggerPoint &&
        !$(customNav).hasClass('custom-navbar--fixed')
      ) {
        $(function () {
          fixedCustomNav()
        })
      }
      if (
        $('.header').hasClass('headroom--pinned') &&
        !$('.header').hasClass('headroom--unpinned')
      ) {
        $(customNav).addClass('custom-navbar--push-down')
        customNavTriggerPoint = distanceFromTop - 72
      } else if (
        !$('.header').hasClass('headroom--pinned') &&
        !$('.header').hasClass('headroom--unpinned')
      ) {
        $(customNav).addClass('custom-navbar--push-down')
        customNavTriggerPoint = distanceFromTop - 72
      } else if (
        !$('.header').hasClass('headroom--pinned') &&
        $('.header').hasClass('headroom--unpinned')
      ) {
        if ($('.header').hasClass('headroom--pinned')) {
          $(customNav).addClass('custom-navbar--push-down')
        } else {
          $(customNav).removeClass('custom-navbar--push-down')
        }
        customNavTriggerPoint = distanceFromTop - 12
      }
    } else {
      // upscroll code
      if (
        $(document).scrollTop() < customNavTriggerPoint &&
        $(customNav).hasClass('custom-navbar--fixed')
      ) {
        $(function () {
          unfixCustomNav()
        })
      }
      if (
        $('.header').hasClass('headroom--pinned') &&
        !$('.header').hasClass('headroom--unpinned')
      ) {
        $(customNav).addClass('custom-navbar--push-down')
        customNavTriggerPoint = distanceFromTop - 72
      }
    }
    lastScrollTop = st
  })
}

export default function initCustomNavbar() {
  if ($(customNav).length) {
    elementTopDistance()
    updateParent()
    $(function () {
      customNavScrollEvents()
    })
  }

  $('.js-custom-nav a').on('click', function (e) {
    const target = e.target.getAttribute('href')
    if (target.startsWith('#')) {
      e.preventDefault()
      var elHeight = $('.js-custom-nav').outerHeight()
      var titleHeight = $('.team__inner h2').outerHeight()
      var titlePadding = $('.team__inner h2').css('padding-top')
      var targetEl = $(this).attr('href')
      $('html, body').animate(
        {
          scrollTop: $(targetEl).offset().top - elHeight - 30
        },
        300
      )
    }
  })
}
