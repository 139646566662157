import anime from 'animejs/lib/anime.es.js';

const isSafariBrowser = () => navigator.userAgent.indexOf('Safari') > -1 && navigator.userAgent.indexOf('Chrome') <= -1

const rnd = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
const colors = ["#D6FF35", "#44D658"];
const rndBorderRadius = () =>
  [...Array(4).keys()].map((x) => rnd(30, 50) + "%").join(" ") +
  " / " +
  [...Array(4).keys()].map((x) => rnd(30, 50) + "%").join(" ");

const createBlob = ({ id, x, y, color, card }) => {
  const blob = document.createElement("div");
  blob.id = `blob-${id}`;
  blob.classList.add("blob");
  // blob.style.top = `${y}%`;
  // blob.style.left = `${x}%`;
  blob.style.backgroundColor = color;
  blob.style.scale = rnd(1, 2);
  blob.style.borderRadius = rndBorderRadius();
  card.appendChild(blob);
  animateBlob(id);
};

const animateBlob = (id) => {
  anime({
    targets: `#blob-${id}`,
    translateX: () => `+=${rnd(-20, 20)}`,
    translateY: () => `+=${rnd(-20, 20)}`,
    borderRadius: () => rndBorderRadius(),
    rotate: () => rnd(-50, 50),
    opacity: () => rnd(0.4, 0.8),
    delay: () => rnd(0, 100),
    scale: () => rnd(1, 2),
    direction: 'alternate',
    loop: true,
    duration: 3400,
    easing: "linear",
    //complete: (anim) => animateBlob(id)
    // complete: anim => { anim.restart(); },
  }).play();
};

const genBlobs = () => {
  const cards = document.getElementsByClassName("orb-blob");
  for (let i = 0; i < cards.length; i++) {
    let card = cards[i];
    card.innerHTML = "";
    [...Array(2).keys()].map(() => {
      const x = rnd(25, 50);
      const y = rnd(25, 50);
      const id = rnd(1, 1000);
      const color = colors[rnd(0, colors.length)];
      createBlob({ id, x, y, color, card });
    });
  }
};

export default function animatedBlob(){
  $(document).ready( genBlobs )
}
