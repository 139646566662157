export default function initDomainChecker() {
  const checkDomainForm = document.getElementById('dm-inspect-domain')
  if (!checkDomainForm) return

  const params = new URLSearchParams(document.location.search)
  const searchedDomain = params.get('domain')

  if (searchedDomain) {
    setTimeout(()=> {
      checkDomainForm.scrollIntoView({ behavior: 'smooth', block: 'center'})
    }, 500)
  }
}
