import $ from 'jquery'
var el = $(".js-green-grid").closest('section');
var elCircle = $(".js-animated-circle")

function greenHoverAnimation() {
  if($(".hero").hasClass('hero--green') || $(".js-green-grid").length > 0){
    $(el).mousemove(function(e) {
      var colorOne = "#018908";
      var colorTwo = '#45C14C';
      var X = e.pageX - this.offsetLeft;
      var Y = e.pageY - this.offsetTop;

      elCircle.innerHTML = X + "x, " + Y + "y";

      var pos = X + "px" + " " + Y + "px";
      var gradient = pos + ", " + colorOne + ", " + colorTwo;

      $(elCircle).css("background", "radial-gradient(circle at " + gradient);
    });
  }
}

export default function initGreenHoverAnimation(){
  $(document).ready( greenHoverAnimation )
}
