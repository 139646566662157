function sendSubscriber(e) {
  e.preventDefault()

  let data = {}
  let formData = $(e.currentTarget).serializeArray()
  formData = formData.forEach((o) => {
    const {name, value} = o
    data[name] = value
  })

  data = { 'action': 'newsletter_subscribe', ...data }

  $(e.currentTarget).addClass('disabled');

  let _message = ''

  $.post(SITE.AJAX_URL, data, function(response) {
    _message = $(e.currentTarget).data('success')
  })
  .fail(function(err) {
    if (err.responseJSON && Array.isArray(err.responseJSON.data)) {
      err.responseJSON.data.forEach( o => {
        _message += `<div>${o.message}</div>`
      })
    }
    console.error({message: _message.replace(/<[^>]*>?/gm, '')})
  })
  .always(function() {
    $(e.currentTarget).removeClass('disabled')
    $(e.currentTarget).find('.newsletter-form__message').html(_message)
  })

}
function resetForm(e) {
  $(e.target).closest('form').find('.newsletter-form__message').html(" ")
}

export default function handleNewsletterSignUp(){
  $(document).on('submit', '#newsletter_subscribe', sendSubscriber )
  $(document).on('focus', '#newsletter_subscribe input', resetForm )
}
