import $ from 'jquery'

const $doc = $(document)
const $win = $(window)

let state = {
  mobile: false
}

function handleToggleAccordion() {
  toggleAccordionItem($(this))
}

function toggleAccordionItem($elm, isReset) {
  const $accordion = $elm.parent().parent().parent()
  const $accordionItem = $elm.parent().parent()
  const $accordionContent = $elm.parent().next()
  const numOfItems = $accordion.find('.accordion__item').length
  const isHorizontal = $accordion.hasClass('accordion--horizontal') && $win.width() >= 960
  const accordionWidth = $accordion.width() - ($accordionItem[0].clientWidth * (numOfItems - 1))
  const toggle = function() {
    isHorizontal && $accordionItem.css('width', accordionWidth)
    !isHorizontal && $accordionContent.slideToggle('fast')
    $accordionItem.toggleClass('active plus-sign minus-sign')
  }

  if (isReset) {
    redrawAccodrion(isHorizontal)
    $accordion.find('.accordion__item').removeClass('active minus-sign').addClass('plus-sign').attr('aria-expanded', 'false')
    toggle() // initial toggle where $elm is the first accordion row
    return false
  }

  if ($accordionItem.hasClass('active')) {
    return false
  } else {
    $accordion.find('.accordion__item').removeClass('active minus-sign').addClass('plus-sign').attr('aria-expanded', 'false')
    $accordion.find('.accordion__item__content').hide('fast')
    $elm.attr('aria-expanded', 'true')
  }

  redrawAccodrion(isHorizontal, $accordionItem)
  toggle()
}

function handleResetAccordion(e, init) {
  if(init) {
    resetAccordion()
    return false
  }

  setTimeout(function() {
    if ($win.width() < 960 && !state.mobile) {
      state.mobile = true
      resetAccordion()
    }

    if ($win.width() >= 960 && state.mobile) {
      state.mobile = false
      resetAccordion()
    }

  }, 250)
}

function redrawAccodrion(isHorizontal, $accordionItem) {
  if (isHorizontal) {
    $(".accordion--horizontal .accordion__item").css('width', '73px')
    $(".accordion--horizontal .accordion__item__content").css('display', '')
  } else {
    // $(".accordion--horizontal .accordion__item").css('width', '')
  }
}
function resetAccordion(){
  $('.accordion').each(function() {
    const inital = $(this).find('.accordion__item')[0]
    const $elm = $(inital).find('button')

    toggleAccordionItem($elm, true)

  })
}

export default function initAccordion() {
  $doc.on('click', '.js-accordion-item button', handleToggleAccordion)
  $win.resize(handleResetAccordion)
  $((e) => { handleResetAccordion(e, true) });
}
