import $ from 'jquery'
const $doc = $(document)

function moveFooterForm(event){
  if($(".footer__wrapper .gform_wrapper.gravity-theme").length){
    var footerForm = $(".footer__wrapper .gform_wrapper.gravity-theme");
    $(".footer__form-wrap").append(footerForm)
  }
}

export default function initFooterForm() {
  $(document).ready( moveFooterForm );
}
