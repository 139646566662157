import $ from 'jquery'
import gsap from 'gsap'

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays*24*60*60*1000));
  var expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

function init(){
  var hasCookie = getCookie('privacy-cookies');
  if(!hasCookie) $(".cookies").show();

  $('.cookies__cta a').on('click', function() {
    $(".cookies").hide();
    setCookie('privacy-cookies', 'true', 1);
  });

  gsap.fromTo(".cookies", {opacity: 0 }, { opacity: 1, delay: 3, duration: .5, ease: "power2.inOut" });
}

export default function initCookies(){
  $(document).ready( init )
}

export {setCookie, getCookie};
