import $ from 'jquery'
const $doc = $(document)

function openChart(event){
  var parent = $(event.target).parent(".chart");
  var childTable = $(parent).find(".chart__table");
  var icon = $(event.target).find(".icon");

  $(childTable).slideToggle().toggleClass('chart-open').attr('aria-expanded', 'true');
  $(icon).addClass('icon-minus').removeClass('plus-sign');

  if(!$(".chart__table").hasClass('chart-open')) {
    $(childTable).attr('aria-expanded', 'false');
    $(icon).removeClass('icon-minus').addClass('plus-sign');
  }
}

export default function initChart() {
  $doc.on('click', '.js-open-chart', openChart)
}
