import $ from 'jquery'
import Typed from 'typed.js'
import gsap from 'gsap'

$.fn.isInViewport = function() {
  var elementTop = $(this).offset().top;
  var elementBottom = elementTop + $(this).outerHeight();
  var viewportTop = $(window).scrollTop();
  var viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

function heroTypeAnimation(){
  let wordsList = $(".hero__title--animated").data('words');
  const wordsArr = wordsList.split(',');
  var typed = new Typed('.hero__title--animated .hero__typed', {
    strings: wordsArr,
    typeSpeed: 130,
    backSpeed: 60,
    loop: true,
  });
}

function homepageHeroAnimations(){
  gsap.to(".home .hero__bg", { scale: 1, duration: 2, ease: "power1.out"});
  gsap.fromTo(".home .hero__grid--white img", { scale: 3 }, { scale: 1, duration: 2, opacity: 1, ease: "power2.inOut" });
  gsap.to(".home .hero__blurred", { opacity: 1, delay: .2, duration: .5, ease: "power2.inOut" });
  gsap.fromTo(".home .hero__title", {y: 50, opacity: 0}, { opacity: 1, force3D: false, y: 0, delay: .5, duration: 1, ease: "Sine.easeOut" });
  gsap.fromTo(".home .header", { y: -100 }, { y: 0, opacity: 1, duration: 1, delay: 0.5, ease: "Sine.easeOut" });
}

function headerColorSwitch(){
  $(window).on('resize scroll', function() {
    if ($('.home .hero').isInViewport()) $(".home .header").addClass('header--black');
    else $(".home .header").removeClass('header--black');
  });
}

function regularHeroAnimation(){
  gsap.fromTo(".hero__title", { opacity: 0 }, { opacity: 1, y: 0, delay: 0, duration: 1 });
}

export default function initHeroTypeAnimation(){
  if($('body').hasClass('home')){
    homepageHeroAnimations();
    heroTypeAnimation();
    headerColorSwitch();
  }
  else{
    regularHeroAnimation();
  }
}
